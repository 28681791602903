/**   This file for linking css for latestsharing/frontend/src/components/App/App.js
  *   We can add more properties or use this properties for other html components when required in next version.
*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
.rootDiv{
  height: 100%;
  display: grid;
  grid-template-rows: 0.2fr 1fr 20fr;
}
.div-top-pad{
    padding-top: 15%;
}
pre{
  font-family: 'Inter', sans-serif;
}
::-webkit-scrollbar { 
  display: none; 
}
.dropdown-menu.show {
  right:0;
}
@media only screen and (max-width:1000px)
{
  .navbar .dropdown-menu a {
    padding: 0;
    text-align: center;
    font-size: .8rem;
    font-weight: 300;
  }
}
