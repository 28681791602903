.uploadFilesDiv {
    display: grid;
    grid-template-rows: 1fr 12fr;
    gap: 1rem;
    height: 91vh;

    .uploadFilesOptionDiv {
        display: flex;
        gap: 3rem;
        justify-content: space-evenly;
        padding: 0.5rem 1rem;
        position: relative;
        background-color: #f3f3f3;
        box-shadow: 6px 5px 5px 2px rgba(0, 0, 0, 0.75);
        align-items: center;

        .buttonDiv {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            span {
                font-weight: bold;
                font-size: 1.2rem;
                opacity: 0.4;
            }
        }

        .modeButton {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            /* color: #274472; */
            color: #0C4160;
            transition: all ease-in 0.1s;
            padding: 0.3rem 2rem;
            border-radius: 55px;
            color: #efefef;
            gap: 0.5rem;
            background-color: #0C4160;
            border: solid 1px #efefef;
            /* background: linear-gradient(to bottom, #373b44, #4286f4); */
            /* background: linear-gradient(to bottom, #24c6dc, #514a9d); */
            background: white;
            color: #0C4160;
            box-shadow: 2px 1px 5px 2px rgba(0, 0, 0, 0.75);
            opacity: 0.5;



            i {
                font-size: 3rem;
            }

            div {
                font-size: 1.1rem;
                font-weight: bold;
                text-align: center;
                line-height: 1.2;
            }


        }

        .modeSelected {
            opacity: 1;
        }

        .modeButton:hover {
            box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.75);
            transform: scale(1.1);
        }


    }

    .uploadconvertOperationDiv {
        display: flex;
        gap: 5rem;
        padding: 1rem;
        width: 70%;
        margin: auto;
        height: 75vh;

        .convertedFilesDiv {
            .convertResultDiv {
                >span {
                    font-weight: bold;
                    margin: 4rem auto;
                    width: fit-content;
                    display: block;
                    opacity: 0.3;
                    font-size: 1.2rem;
                    text-align: center;
                }

                .convertResult {
                    position: initial;
                    border: 0;
                    padding: 1rem;
                    border-bottom: solid 1px;
                    border-radius: 5px;
                    margin: 0 1rem;
                    font-weight: bold;
                    display: grid;
                    grid-template-columns: 6fr 1fr;
                    align-items: center;
                    gap: 1rem;
                    overflow-wrap: anywhere;

                    .statusIcon {
                        font-size: 1.5rem;

                        .fa-circle-check {
                            color: #32CD30;
                        }

                        .fa-circle-xmark {
                            color: #EB1E2B;
                        }


                        span {
                            height: 1.5rem !important;
                            width: 1.5rem !important;
                            color: #3d8ac9;
                        }

                    }
                }
            }
        }

        .FileSection {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem 2rem;
            margin: auto;
            border-radius: 20px;
            border: solid 1px rgba(94, 94, 94, 0.20);
            box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.40);
            background: linear-gradient(180deg, rgba(224, 224, 224, 0.20) 20%, rgba(94, 94, 94, 0.12) 50%);
            height: 100%;
            width: 37%;


            .dateSelector {
                font-weight: bold;
                align-items: end;
                gap: 1.5rem;
                display: flex;

                >div {
                    width: fit-content;
                    margin: auto;

                }

                .addNewVendorDiv {
                    border-radius: 20px;
                    padding: 0.5rem 1.5rem;
                }

                input {
                    text-align: center;
                    margin: 1rem auto 0;
                }
            }

            .fileSelector {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                box-shadow: inset 3px 4px 6px rgba(0, 0, 0, 0.40);
                border-radius: 10px;
                padding: 1rem 2rem;
                overflow-y: scroll;
                background: white;
                height: 47vh;

                .form-group {

                    font-size: 0.8rem;

                    .input-group {
                        height: 4vh;

                        input {
                            font-size: 0.8rem;
                            height: 100%;

                        }
                    }

                    span {
                        height: 100%;
                    }

                    .form-select {
                        font-size: 0.8rem;

                    }

                }




                >div {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    gap: 1rem;
                }
            }
        }

        .uploadedFilesDiv {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            padding: 1rem 0;
            margin: auto;
            border-radius: 20px;
            border: solid 1px rgba(94, 94, 94, 0.20);
            box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.40);
            background: linear-gradient(180deg, rgba(224, 224, 224, 0.20) 20%, rgba(94, 94, 94, 0.12) 50%);
            height: 100%;
            width: 37%;
            justify-items: center;

            display: grid;
            grid-template-rows: 1fr 1fr;

            .uploadedFilesList {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
                height: 100%;

                >span {
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .loader{
                    margin-top: 2rem;
                }


                .uploadedFiles {
                    text-align: center;
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
                    justify-content: flex-start;
                    box-shadow: inset 3px 4px 6px rgba(0, 0, 0, 0.40);
                    border-radius: 10px;
                    padding: 1.5rem 2rem;
                    overflow-y: scroll;
                    background: white;
                    align-items: center;
                    width: 22vw;
                    height: 25vh;
                    scrollbar-width: thin;





                    >div {
                        display: grid;
                        width: 100%;
                        align-items: center;
                        gap: 1rem;
                        background: linear-gradient(to right, #8e9eab, #eef2f3);
                        background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
                        padding: 0.3rem 1.5rem;
                        grid-template-columns: 5fr 1fr;
                        justify-items: baseline;
                        border-radius: 30px;
                        box-shadow: 1px 3px 5px 2px rgba(0, 0, 0, 0.40);
                        transition: all 0.2s ease;
                        opacity: 0.8;
                        cursor: pointer;

                        button {
                            border: none;
                            height: 22px;
                            width: 22px;
                            padding: 0;
                            background: transparent;

                            img {
                                height: 100%;
                                width: 100%;
                            }

                        }

                        span {
                            font-size: 1rem;
                            font-weight: bolder;
                            text-align: left;
                            width: 100%;
                            overflow: hidden;
                            white-space: pre;
                        }
                    }

                    >div:hover {
                        transform: scale(1.05);
                        opacity: 1;
                    }
                }

            }

            .uploadedFilesLogs {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;


                >span {
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .LogsDiv {
                    box-shadow: inset 3px 4px 6px rgba(0, 0, 0, 0.40);
                    border-radius: 10px;
                    padding: 0.5rem 1rem;
                    overflow-y: scroll;
                    background: white;
                    min-width: 22vw;
                    width: 22vw;
                    height: 20vh;
                    scroll-behavior: smooth;
                    text-align: center;

                    .log {
                        display: grid;
                        grid-template-columns: 2fr 3fr;
                        font-size: 0.8rem;
                        font-weight: bold;

                        span{
                            line-height: 1.4;
                            overflow: hidden;
                            /* white-space: pre; */
                        }
                    }

                    .success {
                        color:#04D010;
                    }

                    .error {
                        color: red;
                    }

                    .default {
                        color: gray;
                    }

                    .warning {
                        color: #5e2909;
                    }
                }
            }
        }


        .convertButtondiv {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                font-size: 1.3rem;
                border-radius: 40px;
                text-transform: capitalize;
                padding: 0.5rem 2rem;
                font-weight: bold;
                transition: all ease-in 0.2s;
                gap: 0.5rem;
                border: solid 2px white;

            }

            button:hover {
                transform: scale(1.05);
            }
        }

        .fileListDiv {
            position: relative;

            >div {
                position: absolute;
                left: 0;
                right: 0;
                border-radius: 20px;
                margin: auto;
                border: 1px solid rgba(140, 136, 136, 0.564);
                box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.40);
                background: linear-gradient(180deg, rgba(224, 224, 224, 0.20) 20%, rgba(94, 94, 94, 0.12) 50%);
            }

            .filesDataDiv {
                height: 94%;
                width: 80%;
                background-color: #efefef;
                z-index: 1;
                bottom: 0;

            }

            .filesListBack {
                width: 70%;
                z-index: 0;
                background-color: #faf9f9;
                height: 20%;
                font-weight: bold;
                text-align: center;
                padding: 0.5rem 0;
                top: 0;
            }
        }
    }
}


/*--------------------------------------------------------------------------*/

@media only screen and (max-width:1000px) {
    .uploadFilesDiv{
        .uploadFilesOptionDiv {
            gap: 1rem;
            padding: 0.5rem 1rem;
            position: relative;
    
            .buttonDiv {
                gap: 1rem;
    
                span {
                    font-weight: bold;
                    font-size: 0.8rem;
                    opacity: 0.4;
                }
            }
    
            .modeButton {
                
                
                padding: 0.3rem 2rem;
                border-radius: 55px;
                gap: 0.5rem;     

                i {
                    font-size: 3rem;
                }
    
                div {
                    font-size: 0.8rem;
                    font-weight: bold;
                    text-align: center;
                    line-height: 1.2;
                }
    
    
            }
    
            .modeSelected {
                opacity: 1;
            }
    
            .modeButton:hover {
                box-shadow: 5px 3px 5px 2px rgba(0, 0, 0, 0.75);
                transform: scale(1.1);
            }
    
    
        }

        .uploadconvertOperationDiv {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            gap: 2rem;
            width: 85%;
    
            .fileListDiv {
                position: relative;
    
                div {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 20px;
                    margin: auto;
                    border: 1px solid rgba(140, 136, 136, 0.564);
                }
    
                .filesDataDiv {
                    height: 88%;
                    width: 80%;
                    background-color: #efefef;
                    z-index: 1;
                    font-size: 0.8rem;
                    overflow-y: scroll;
    
                }
    
                .filesListBack {
                    font-size: 0.8rem;
                    width: 70%;
                    z-index: 0;
                    background-color: #faf9f9;
                    height: 100%;
                    font-weight: bold;
                    text-align: center;
                    padding: 0.2rem 0;
                }
            }

            .uploadedFilesDiv {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
                padding: 1rem 0;
                
                width: 100%;
    
                display: grid;
                grid-template-rows: 1fr 1fr;
    
                .uploadedFilesList {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;
                    
    
    
                    >span {
                        font-size: 1rem;
                        font-weight: bold;
                    }
    
    
                    .uploadedFiles {
                        gap: 1rem;
                        padding: 1.5rem 2rem;

                        width: 70vw;
                        height: 15vh;
    
                        >div {                            
                            gap: 1rem;
                            padding: 0.3rem 1.5rem;
                            
    
                            button {
                                height: 17px;
                                width: 17px;
                                padding: 0;
    
                                img {
                                    height: 100%;
                                    width: 100%;
                                }
    
                            }
    
                            span {
                                font-size: 0.7rem;
                                font-weight: bolder;
                                text-align: left;
                            }
                        }
                    }
    
                }
    
                .uploadedFilesLogs {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;
    
    
                    >span {
                        font-size: 1rem;
                        font-weight: bold;
                    }
    
                    .LogsDiv {
                        padding: 0.5rem 1rem;
                        width: 70vw;
                        height: 15vh;
    
                        .log {
                            display: grid;
                            grid-template-columns: 0.7fr 1fr;
                            font-size: 0.7rem;
                            font-weight: bold;
    
                            span{
                                line-height: 1.4;
                            }
                        }
    
                        .success {
                            color:#04D010;
                        }
    
                        .error {
                            color: red;
                        }
    
                        .default {
                            color: gray;
                        }
    
                        .warning {
                            color: #5e2909;
                        }
                    }
                }
            }
    
    
            
    
            .convertedFilesDiv {
                min-height: 25vh;
                height: fit-content;
                margin-top: 1rem;
    
                .convertResultDiv {
                    >span {
                        font-weight: bold;
                        margin: 2rem auto;
                        width: fit-content;
                        display: block;
                        opacity: 0.3;
                        font-size: 1rem;
                        text-align: center;
                    }
    
                    .convertResult {
                        position: initial;
                        border: 0;
                        padding: 0.5rem;
                        border-bottom: solid 1px;
                        border-radius: 5px;
                        margin: 0 1rem;
                        font-weight: bold;
                        display: grid;
                        grid-template-columns: 6fr 1fr;
                        align-items: center;
                        gap: 1rem;
                        overflow-wrap: anywhere;
    
                        .statusIcon {
                            font-size: 1rem;
    
                            .fa-circle-check {
                                color: #32CD30;
                            }
    
                            .fa-circle-xmark {
                                color: #EB1E2B;
                            }
    
    
                            span {
                                height: 1.5rem !important;
                                width: 1.5rem !important;
                                color: #3d8ac9;
                            }
    
                        }
                    }
                }
            }
    
            .FileSection {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                padding: 0.5rem 1rem;
                width: fit-content;
    
    
                .dateSelector {
                    font-weight: bold;
                    align-items: end;
                    gap: 0.5rem;
                    display: grid;
                    padding: 0;
                    font-size: 0.8rem;
    
                    .addNewVendorDiv {
                        grid-column: none;
                    }
    
                    input {
                        text-align: center;
                        margin: 0.5rem auto 0;
                        font-size: 0.8rem;
                    }
                }
    
                .fileSelector {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                    height: 21vh;
    
    
    
                    >div {
                        flex-direction: column;
                        padding: 0 !important;
                        margin: 0 !important;
                        gap: 0;
                        align-items: center;
                    }

                    .form-group {
                        padding: 0 !important;
    
                        .input-group {
                            height: 4vh;
    
                            input {
                                font-size: 0.8rem;
                                height: 100%;
    
                            }
                        }
    
                        span {
                            height: 100%;
                        }
    
                        .form-select {
                            font-size: 0.8rem;
    
                        }
    
                    }

                    .vendorSelector{
                        width: 100% !important;
                    }
                }

                .convertButtondiv {
                    display: flex;
                    align-items: center;
                    justify-content: center;
        
                    button {
                        font-size: 1rem;
                        border-radius: 40px;
                        display: flex;
                        text-transform: capitalize;
                        padding: 0.5rem 1rem;
                        font-weight: bold;
                        transition: all ease-in 0.2s;
                        gap: 0.5rem;
        
                        span:last-child {
                            display: block;
                            font-size: 0.8rem;
                        }
                    }
        
                    button:hover {
                        transform: scale(1.05);
                    }
                }
    
    
            }
        }
    }


    .userContainer {
        flex-direction: column;
    }

    .btn {
        font-size: 9px;
    }

    .navbar-nav {
        gap: 0.5rem;
        align-items: center;
        margin: 1rem auto;
    }

    .convertMergeDiv {
        display: block;
        grid-template-columns: auto;
        gap: 1rem;
        height: auto;
    }

    .convertMergeOptionDiv {
        flex-direction: row;
        gap: 1rem;
        padding: 1rem 0;

        svg {
            display: none;
        }



        div {
            display: flex;
            flex-direction: row;
            height: auto;
            gap: 0.5rem;

            i {
                font-size: 1rem;
            }

            div {
                font-size: 1rem;
                line-height: 1;
            }
        }

        .modeSelected {
            color: white;
            background-color: #274472;
            padding: 0.5rem 1rem;

            div {
                color: white;
            }
        }


    }

    

}