/**   This file for linking css for /latestsharing/frontend/src/components/App/AppChildren/AppContainerChildren/MessengerChildren/MessageList.js
  *   We can add more properties or use this properties for other html components when required in next version.
*/
/* .graphWrapper {
  position: absolute;
  height: 70%;
  width: 60%;
  top: 50%;
  left: 50%;
  z-index: 10;
  background: aliceblue;
  border-radius: 20px;
  border: solid 4px #3378b5;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;

  .graphText{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    >span{
      
    }
  }


  .closeIcon {
    top: 1rem;
    right: 1rem;
    position: absolute;
    opacity: 0.5;
    transition: all 0.2s ease;
    cursor: pointer;
    z-index: 20;
    scale: 1.2;
  }

  .closeIcon:hover {
    transform: scale(1.2);
    opacity: 1;
  }
} */

.div1 {
  overflow-y: auto;
  position: absolute;
  height: 90%;
}

.div2 {
  height: 50px;
  clear: both;
  float: left;
}

@media (max-width: 1000px) {
  .div1 {
    overflow-y: auto;
    position: absolute;
    height: 88%;
  }
}