.pivotTable{
    td{
        padding: 0 !important;
        color: #1266f1 !important;


        .pivotCellContainer{
            padding: 0.6rem;
            position: relative;
            .graphButton{
                position: absolute;
                top: 0.2rem;
                right: 0.2rem;
                opacity: 0.7;
                transition: all 0.2s ease;
                cursor: pointer;
                font-size: 1.2rem;
            }
            .graphButton:hover{
                transform: scale(1.2);
                opacity: 1;
            }
            >a{
                /* text-shadow: 0px 0px 1px #4285f4; */
                color: #1266f1 !important;

            }
        }
    }
}

@media only screen and (max-width:1000px) {
    .pivotTable{
        td{
    
    
            .pivotCellContainer{
                padding: 0rem;
                .graphButton{
                    top: 0.2rem;
                    right: 0.2rem;
                    font-size: 0.6rem;
                }
            }
        }
    }
}
