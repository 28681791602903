/**  This file for linking css for /frontend/src/components/App/AppChildren/AppContainerChildren/MessengerChildren/TextBox.js
 *  We can add more properties or use this properties for other html components when required in next version.
*/
.text-area{
    width: 100%;
    /* resize: none; */
    margin: auto;
    /* margin-top: 20px; */
    border: 2px solid black;
    border-radius: 5px;
}
.text-area::placeholder{
    text-align: center;
    line-height:30px;
    font-size: medium;
}
