div.dataTables_wrapper div.mdb-datatable-filter input {
  width: auto;
  margin-left: 0.5rem;
  display: inline-block;
}

.messageData{
  margin-top: 1rem !important;
}

.messageData .mdb-datatable>div:first-child {
  position: absolute;
  top: 0.5%;
  right: 8.7%;
}


.messageData .mdb-datatable-filter {
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  width: 1.4rem;
  height: 1.4rem;
  position: relative;

  @media (prefers-reduced-motion: no-preference) {
    transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }
}

.messageData .mdb-datatable-filter::before {
  content: "🔍";
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

/* .mdb-datatable-filter::before:focus-within{
    right: 20%; 
      transform : translate(0, 0);
      .mdb-datatable-filter{
        width: 10rem;
    background: white;
    border-radius: 10px;
      }

  } */

.messageData .mdb-datatable-filter:focus-within {
  width: 10rem;
  background: white;
  border-radius: 10px;
  padding-left: 1.5rem;
}

.messageData .cursor-pointer {
  cursor: pointer;
}

.messageData .mdb-datatable-filter:hover::before {
  display: none;
}

.messageData .mdb-datatable-filter:focus-within::before {
  right: 0;
  right: 5%;
  transform: none;
  left: auto;
}

/* .searchDiv{
  table{
    table-layout: fixed;
    thead{
      tr{
        th{
          width: 7vw;
          padding: 0.25rem;
        }
      }
      
    }
    tbody{
      tr{
        display: inline-flex;

        .reportVal{
          cursor: pointer;
        }
        td{
          width: 7vw;
          display: block;
          height: 5vh;
          overflow: hidden;
          border: solid 1px #e2e2e2;
          border-top-width: 0;
          border-bottom-width: 0;
        }
      }
    }
  }
} */

.searchDiv{
  table{
    border-spacing: 0;
    border: solid 0.5px #E2E2E2;

    thead{
      tr{
        th{
          font-size: 0.9rem;
          font-weight: 700;
          text-align: start;
          width: 5vw;
          min-width: auto !important;
          white-space: nowrap;
          text-transform: capitalize;
          border: solid 0.5px #E2E2E2;
          padding: 0.7rem 0.5rem !important;
          border-bottom: #E2E2E2;
          cursor: pointer;
        }
      }
      
    }
    tbody{
      tr{

        .reportVal{
          cursor: pointer;
        }
        td{
          padding: 0.5rem !important;
          height: 1rem !important;
          overflow: hidden;
          text-align: left !important;
          text-transform: uppercase;
          font-size: 0.9rem;
          font-weight: 300;
          white-space: nowrap;
          border: solid 0.5px #E2E2E2;
          max-width: 10vw;

        }
      }
    }
  }
}

@media only screen and (max-width:1500px) {
  .messageData .mdb-datatable-filter {
    width: 0.9rem;
    height: 0.9rem;
    padding: 0.5rem;
  }

  .messageData .mdb-datatable>div:first-child {
    top: 4.2%;
    right: 6%;

    input{
      padding: 0.2rem 1rem; 
    }

    input::placeholder{
      font-size: 0.8rem;
      padding: 0 1rem;
    }
  }

  .messageData .mdb-datatable-filter::before {
   font-size: 0.5rem;
  }
  div.mdb-datatable div.mdb-datatable-info, div.mdb-datatable div.mdb-dataTables_paginate, div.mdb-datatable div.mdb-datatable-entries {
    font-size: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
}
.custom-select{
  font-size: 0.5rem;
}
.searchDiv > span {
  font-size: 0.5rem;
}
.messageData .mdb-datatable-filter:focus-within {
  width: 10rem;
  height: 2.5vh;
  font-size: 0.6rem;
}
}

:root {
  --right-margin: 8.8%;
  --top-margin: 0.5%;
}

@media screen and (max-width: 1400px) {
  :root {
    --right-margin: 9.5%;
    --top-margin: 0.5%;
  }
}

@media screen and (max-width: 1300px) {
  :root {
    --right-margin: 10.5%;
    --top-margin: 0.5%;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    --right-margin: 11.5%;
    --top-margin: 0.5%;
  }
}

@media screen and (max-width: 1100px) {
  :root {
    --right-margin: 12.5%;
    --top-margin: 0.5%;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    --right-margin: 7.5%;
    --top-margin: 2.6%;
  }
}

@media screen and (max-width: 800px) {
  :root {
    --right-margin: 9.5%;
  }
}

@media screen and (max-width: 700px) {
  :root {
    --right-margin: 10.5%;
  }
}

@media screen and (max-width: 500px) {
  :root {
    --right-margin: 12.5%;
    --top-margin: 3%;
  }
  
}
.messageData .mdb-datatable > div:first-child {
  right: var(--right-margin);
  top: var(--top-margin);
  input {
    padding: 0.2rem 1rem;
  }
  input::placeholder {
    font-size: 0.8rem;
    padding: 0 1rem;
  }
}