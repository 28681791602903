#tutorialBackGround {
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(3px);
    z-index: 100;
    width: 100vw;
}

#tutorial {
    z-index: 3;
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    width: 60%;
    height: 80%;
    border: 2px solid #34495e;
    border-radius: 30px;
    text-align: center;
    margin: 2rem auto;
    padding: 2rem;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
    display: grid;
    grid-template-rows: 1fr 4fr 8fr 1fr;
    align-items: center
}

#tutorialText {
    display: block;
    text-align: left;
    margin: 0 auto;
    width: 90%;

    .typesOfQuery {
        >p {
            font-size: 1.2rem;
        }

        .typesOfQueryExamples {
            font-size: 1.3rem;
            margin-left: 4rem;
            color: grey;
            font-weight: bold;
        }
    }
}

#controlButtons {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    align-items: center;
    justify-items: center;
}

#controlButtons button {
    border-radius: 20px;
    opacity: 0.7;
    transition: ease-in;
    color: #ffffff;
    background-color: #1abc9c;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;

}


#controlButtons button:hover {
    opacity: 1;
    background-color: #48c9b0;
    scale: 1.1;
}

#previousButton:disabled {
    background-color: grey !important;
    color: white;
    opacity: 0.3;
}

#tutorialCounter {
    position: absolute;
    right: 5%;
    top: 5%;
}

#mainTutorialImageDiv {
    margin: 0 auto;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    gap: 4rem;

    #LOGO {
        height: 100%;
    }

    #T5 {
        height: 17vh;
    }

    #M3 {
        width: 80%;
        height: fit-content;
    }

    #M4 {
        height: 17vh;
    }

    #M5 {
        scale: 1.5;
        transform: translate(0, 10%);
    }

    #M7 {
        scale: 1.3;
        transform: translate(10%, 0);
    }

    #M8 {
        scale: 0.8;
        transform: translate(10%, 0);
    }

    #M9 {
        scale: 1.6;
        transform: translate(0, 10%);
    }

    #M10 {
        scale: 0.9;
    }

    #M11 {
        scale: 1.6;
    }

    #M12 {
        scale: 0.8;
    }

    #M13 {
        scale: 1.2;
        transform: translate(0, -9%);
    }

}


.mainTutorialImage {
    width: -webkit-fill-available;
    height: 28vh;
    flex-basis: fit-content;
}

#tutorial>h3 {
    padding-bottom: 5px;
    font-size: 2rem;
}

#tutorial>h6 {
    padding: 5px 20px 5px 20px;
}

#tutorial>p {
    padding: 5px 20px 5px 20px;
}

#tutorial>ul li {
    list-style: none;
    padding-bottom: 5px;
    padding-right: 40px;
    font-size: 14px;
}


#mainText div {
    width: 25px;
    height: 25px;
    display: inline-block;
    position: relative;
    top: 0.35em;
    margin: 0 0.42em;
}

@media only screen and (max-width:1000px) {
    #tutorial {
        width: 90%;
        height: 70vh;
        display: flex;
        flex-direction: column;
    }

    #tutorialBackGround {
        height: 115vh;

    }


    #tutorialText {
        display: block;
        text-align: center;
        padding: 0;
    }

    #controlButtons {

        /* width: 85%; */
    }

    #controlButtons button {
        font-size: 0.7rem;
    }


    .typesOfQuery>p {
        font-size: 0.55rem !important;
        text-align: left;
    }

    #tutorial p {
        font-size: 0.7rem;
    }



    #controlButtons button:hover {
        opacity: 1;
        background-color: #48c9b0;
        scale: 1.1;
    }

    #previousButton:disabled {
        background-color: grey !important;
        color: white;
        opacity: 0.3;
    }

    #tutorialCounter {
        position: absolute;
        right: 5%;
        top: 5%;
    }

    #tutorial>h3 {
        font-size: 1.9rem;
        margin: 0;
    }

    #tutorial>h6 {
        margin: 0;
        font-size: 0.8rem;
    }

    #tutorial>p {
        padding: 5px 20px 5px 20px;
        padding: 0;
    }

    #tutorial>ul li {
        list-style: none;
        padding-bottom: 5px;
        padding-right: 40px;
        font-size: 14px;
    }


    #mainText div {
        width: 25px;
        height: 25px;
        display: inline-block;
        position: relative;
        top: 0.35em;
        margin: 0 0.42em;
    }

    #mainTutorialImageDiv {
        margin: 1rem;
        gap: 1rem;
        flex-direction: column;

        #LOGO {
            height: 100%;
        }

        #T1,
        #T2,
        #T3 {
            height: 9vh;
            width: fit-content;
        }

        #T4 {
            height: 22vh;
            width: fit-content;
        }

        #T5 {
            height: 9vh;
            width: fit-content;
        }

        #T6,
        #T7,
        #T8 {
            height: 9vh;
            width: fit-content;
        }

        #T9,
        #T10 {
            height: 15vh;
            width: fit-content;
        }

        /* #M5 {
            scale: 1.8;
    transform: translate(-10%, 30%);
        }
        #M6{
            scale: 1.2;
    transform: translate(15%,20%);
        }

        #M7 {
            scale: 1.3;
    transform: translate(55%, 0);
        }

        #M8 {
            scale: 0.8;
    transform: translate(-55%, 150%);
        }

        #M9 {
            scale: 1.6;
            transform: translate(0, 10%);
        }

        #M10 {
            scale: 1;
            transform: translate(-10%,20%);
        }

        #M11 {
            scale: 1.9;
    transform: translate(5%,20%);
        }
        #M12 {
            scale: 1;
    transform: translate(-10%,0);
        }
        #M13 {
            scale: 1.7;
    transform: translate(0,7%);
        } */
    }
}