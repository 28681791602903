/**   This file for linking css for /latestsharing/frontend/src/components/App/AppChildren/AppContainerChildren/Messenger.js
  *   We can add more properties or use this properties for other html components when required in next version.
*/
.pos-relative {
    position: relative;
}
.btn{
    border-radius: 20px;
}
.mdb-card {
    height: 85vh;
}

.pos-absolute {
    position: absolute;
    bottom: 0;
}

.ali-cen {
    align-items: center;
}

.bottom-bar {
    align-items: center;
    justify-content: center;
}

.messengerDiv {
    display: grid;
    grid-template-columns: 2.5fr 13fr;
    

    >div:last-child {
        padding: 0.5rem 2rem 0.5rem 0;
    }

    .MuiFormControl-root * {
        color: #4285f4 !important;
        border-color: #4285f4 !important;
    }
}

.selectSource {
    width: auto;
    text-align: center;
    /* position: absolute; */
    left: 2%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    padding: 1rem 0.5rem;
    position: relative;
    z-index: 10;

    >div {
        width: 80%;
    }

    .fileDatesLoader {
        height: 2rem !important;
        width: 2rem !important;
    }

    .uploadedFilesDiv {
        width: 100%;
        border-radius: 10px;
        height: 37vh;
        overflow-y: auto;
        padding: 0 0.5rem;
        scrollbar-width: thin;
        font-size: 1rem;

        >span {
            font-weight: bolder;
        }

        .uploadedFileWrapper{
            position: initial;
                padding: 0.4rem 0.5rem;
                /* border-bottom: solid 1px; */
                border-radius: 40px;
                font-weight: bolder;                
                width: 95%;
                margin: 0.5rem auto;
                cursor: pointer;
                transition: all 0.2s ease-in;
                border: solid 1px #E3E8E9;
                box-shadow: 3px 3px 7px -4px rgba(0, 0, 0, 0.75);
                text-align: left;

            
            .uploadedFile {
                width: 80%;
                margin: auto;
                display: grid;
                grid-template-columns: 1fr 10fr;
                gap: 0.5rem;
                overflow-wrap: anywhere;
                font-size: 0.9rem;
                justify-items: baseline;
                padding-top: 0.3rem;
                font-family: 'Nunito', sans-serif;
                font-weight: 600;
                letter-spacing: 0.5px;
                padding: 0.4rem;
                
    
                button {
                    border: 0;
                    font-size: 1.2rem;
                    padding: 0;
                    width: fit-content;
                    transition: all ease-in 0.2s;
                    background: inherit;
    
    
                    .fa-solid {
                        opacity: 1;
                        color: #32CD30;
                        /* border: 1px solid #32CD30;
                        border-radius: 50%;
                        padding: 0.2rem;
                        color: #32CD30;   */
                    }
    
                    .fa-regular {
                        opacity: 0.7;
                        /* border-radius: 50%;
                        padding: 0.2rem;
                        color: #777a77; */
                    }
                }
    
                button:hover {
                    transform: scale(1.01);
                }
    
                >span {
                    display: flex;
                    gap: 1rem;
                }
            }    
        }

        .uploadedFileWrapper:hover{
            transform: scale(1.05);
            /* background-color: #1a1b1b;
            box-shadow: 3px 3px 7px -4px rgba(54, 140, 253, 0.75);
            color: white; */
        }

        
    }

    .preferenceDiv
    {
        position: absolute;
        bottom: 10%;
        width: 100%;

        button{
            border-radius: 10px;
        }
        
    }
}

.prefenrenceWrapper{
    .MuiPaper-root{
        width: 50vw;
        height: 70vh;
    }
    .preferenceForm{
        padding: 2rem;
        display: flex;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        
        

        .columnPreference{
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            .columnsWrapper{
                height: 100%;

                >div{
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;
                    height: 100%;
                    scrollbar-width: thin;
                    
                    >div{
                        background-color: #E3E8E9;
                        padding: 0.5rem 1rem;
                        border-radius: 20px;
                        cursor: grab;
                        margin: 0.5rem;
                        text-align: center;
                        display: flex;
                        justify-content: space-between;

                        .editColumnIcon{
                            button{
                                padding: 0;
                            }
                        }
                        
                    }
                    .hiddenCol{
                        background-color: #f1f1f1;
                        opacity: 0.4;
                    }
                    
                }
            }
        }

        .saveButton{
            
        }
    }

}

.graphWrapper {
    position: absolute;
    height: fit-content;
    width: 60%;
    top: 50%;
    left: 50%;
    z-index: 10;
    background: aliceblue;
    border-radius: 20px;
    border: solid 4px #3378b5;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    gap: 0.5rem;
  
    .graphText{
      display: flex;
      flex-direction: column;
      width: 80%;
      align-items: center;
      >span{
        
      }
    }

    .graphEntitySelector{  
        .MuiInputBase-root {
            border-radius: 20px;
        }
        *{
            color: #979797 !important;
            border-color: #979797 !important;
        }
    }
  
  
    .closeIcon {
      top: 1rem;
      right: 1rem;
      position: absolute;
      opacity: 0.5;
      transition: all 0.2s ease;
      cursor: pointer;
      z-index: 20;
      scale: 1.2;
    }
  
    .closeIcon:hover {
      transform: scale(1.2);
      opacity: 1;
    }

    .graphComp>div{
        width: auto !important;
    }
  }


.selectSource .MuiInputBase-root {
    border-radius: 20px;
}

.MuiPaper-root {
    border-radius: 10px !important;
}

.bannerDiv {
    margin: auto auto 0.5rem;
    border: solid 2px darkgrey;
    border-radius: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 0.3rem 0.2rem 0 0.2rem;
    line-height: 1rem;
    opacity: 0.9;
    background-color: #E3E8E9;
    position: relative;
}

.bannerDiv .closeIcon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}

.queryInput input {
    border-radius: 20px;
    line-height: 12px;
    padding: 0.6rem 1rem;
    font-size: 1.5rem;
    height: 2.5rem;
}

.queryInput {
    margin: 0 auto 0.5rem;
}

@media only screen and (max-width: 1000px) {

    .MuiDialog-container{
        .MuiDialogTitle-root{
            text-align: center;
            padding: 2rem;
        }
    }

    .messengerDiv {
        display: flex;
        flex-direction: column;
        margin-top: 0 !important;

        /* height: 75vh; */

        >div:last-child {
            padding: 0 0.8rem;
        }

        .selectSourceButton {
            width: 50%;
            margin: auto;
            border-radius: 20px;
            font-size: 0.7rem;
            padding: 0.2rem 2rem;
            margin: 0.8rem auto;
        }


        .selectSource {
            
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
                "fileTypeSelector fileDateSelector"
                "uploadedFilesDiv uploadedFilesDiv";
            gap: 0;

            

            .fileTypeSelector {
                grid-area: fileTypeSelector;
            }

            .fileDateSelector {
                grid-area: fileDateSelector;
            }

            .MuiFormControl-root * {
                font-size: 0.7rem;
            }


            >div {
                width: 70%;
            }

            .fileDatesLoader {
                height: 2rem !important;
                width: 2rem !important;
            }

            .uploadedFilesDiv {
                height: 10vh;
                grid-area: uploadedFilesDiv;
                padding: 0 2rem;
                font-size: 0.8rem;

                >span {
                    font-weight: bolder;
                }

                .uploadedFile {
                    font-size: 0.7rem;
                    padding: 0.2rem 0.5rem;


                    button {
                        border: 0;
                        font-size: 1rem;
                        padding: 0;
                        width: fit-content;
                        transition: all ease-in 0.2s;
                        background: inherit;


                        .fa-solid {
                            opacity: 1;
                            color: #32CD30;
                        }

                        .fa-regular {
                            opacity: 0.7;
                            color: #777a77;
                        }
                    }

                    button:hover {
                        transform: scale(1.01);

                    }

                    >span {
                        display: flex;
                        gap: 1rem;
                    }
                }

            }

            
        }

        .querybotDiv {
            padding: 0 1rem;
            height: 100%;

            .mdb-card {
                padding: 1rem;
                height: 100%;

                >div {
                    left: 0;
                    right: 0;
                    padding: 0.5rem 1rem;
                    /* margin: 0.5rem auto; */
                }

                >div:last-child{
                    background-color: white;
                }

                .queryInput {
                    align-items: center;
                    justify-items: center;
                    display: grid;
                    grid-template-columns: 2fr 1fr;

                    >div:first-child {
                        width: 100%;
                        padding: 0;
                    }

                    button {
                        border-radius: 34px;
                        font-size: 0.6rem;
                        padding: 0.2rem 1rem;
                        margin: 0.2rem;
                    }

                    input {
                        border-radius: 20px;
                        line-height: 12px;
                        padding: 0.6rem 1rem;
                        font-size: 1rem;
                        height: 2rem;
                    }

                    input::placeholder {
                        font-size: 0.8rem;
                    }
                }
            }
        }

    }

    .graphWrapper {
        height: auto;
        width: 90%;
        padding: 1rem;
        gap: 0.5rem;
      
        .graphText{
          width: 80%;
          >span{
            
          }
        }
    
        .graphEntitySelector{  
            .MuiInputBase-root {
                border-radius: 20px;
            }
            *{
                color: #979797 !important;
                border-color: #979797 !important;
            }
        }

        /* .css-kifx7q-MuiResponsiveChart-container{
            width: auto !important;
        }

        .css-1jxptqj{
            width: auto !important;
        } */
      
      
        .closeIcon {
          top: 1rem;
          right: 1rem;
          position: absolute;
          opacity: 0.5;
          transition: all 0.2s ease;
          cursor: pointer;
          z-index: 20;
          scale: 1.2;
        }
      
        .closeIcon:hover {
          transform: scale(1.2);
          opacity: 1;
        }
      }

    .bannerDiv .closeIcon {
        right: 0.3rem;
        top: 0.3rem;
    }

    .selectSource {
        width: 100%;
        margin: -6% auto 0;
        position: sticky;
        padding: 1rem 2rem;
        min-height: 20vh;

        .preferenceDiv
        {
            bottom: 20%;

            button{
                border-radius: 10px;
            }
            
        }

    }

    .prefenrenceWrapper{
        .MuiPaper-root{
            width: 90vw;
            height: 60vh;
        }
        .preferenceForm{
            padding: 2rem;
            display: flex;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            
            
    
            .columnPreference{
                overflow: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .columnsWrapper{
                    height: 100%;
    
                    >div{
                        display: flex;
                        flex-direction: column;
                        overflow-y: scroll;
                        height: 100%;
                        scrollbar-width: thin;
                        
                        >div{
                            background-color: #E3E8E9;
                            padding: 0.5rem 1rem;
                            border-radius: 20px;
                            cursor: grab;
                            margin: 0.5rem;
                            text-align: center;
                            display: flex;
                            justify-content: space-between;
    
                            .editColumnIcon{
                                button{
                                    padding: 0;
                                }
                            }
                            
                        }
                        .hiddenCol{
                            background-color: #f1f1f1;
                            opacity: 0.4;
                        }
                        
                    }
                }
            }
    
            .saveButton{
    
            }
        }
    
    }

    .bannerText {
        font-size: 0.6rem;
        margin: 0 !important;
    }

    .bannerDiv {
        width: 95%;
        margin: 0 0 0.5rem
    }

    .bannerDiv .closeIcon svg {
        height: 0.8rem;
        width: 0.8rem;
    }

    .queryInput textarea {
        height: 5vh;
    }

    .bannerTextarea {
        width: 95% !important;
    }

}