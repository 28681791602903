/* .selectResponse{
    opacity: 0.5;
    cursor: pointer;
    font-size: 1.3rem;
    z-index: 10;
}
.selectResponse:hover{
    opacity: 1;
}
.exportJson
{
    font-size: 1.3rem;
    z-index: 10;
    opacity: 0.5;
    cursor: pointer;
}
.exportJson:hover
{
    opacity: 1;
} */

.accessbilityBtns {
    right: 0;
    gap: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 1.4%;
}
.MuiPaper-root {
    .statForm {
        width: 30vw;
        height: 35vh;
        text-align: center;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .MuiFormGroup-root{
            gap: 2rem;
            align-items: center;

            .formFields{
                display: flex;
                gap: 1rem;
            }
            .MuiButton-root{
                width: 50%;
                border-radius: 20px;
                font-size: 1rem;
            }
        }
        
    }
}

.accessbilityBtns>div {
    font-size: 1.3rem;
    z-index: 10;
    opacity: 0.5;
    cursor: pointer;
}

.accessbilityBtns>div:hover {
    opacity: 1;
}
.messageDiv {
    padding-right: 1.5rem !important;

    .messageText{
        width: 90%;
        display: block;
    }

    .messageSmall{
        display: flex;
        justify-content: space-between;

    }

    .editQueryLink{
        cursor: pointer;
        transition: all 0.2s ease;
    }
    .editQueryLink:hover{
        transform: scale(1.1);
    }

    .dataTableLink{
        font-weight: bold;
        color: #4285f4;
        cursor: pointer;
    }

    .messageEditButtonDiv{
        position: absolute;
        right: 2%;
        cursor: pointer;
        transition: all 0.2s ease;
        z-index: 1;

        *{
            position: relative;
            z-index: -1;
        }
    }
    .messageEditButton{
        color: grey; 
    }
    .messageEditButtonDiv:hover{
        transform: scale(1.1);
    }
}
.errorMessage{
    color: #E80000;
}
.diamondFrame{
    height: 60vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    /* border: solid #a0b8e0 2px; */
    border-radius: 10px;

    img{
        width: 100%;
    }
}
.reportQuery{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    margin: 0.5rem 0;   
}

.reportQueryBtn{
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: none;
    border: none;
    background-color: transparent;
    font-size: 1rem; 
}
@media only screen and (max-width:1000px) {
    .messageDiv {
        padding: 0.4rem 0 !important;

        >div{
            overflow: visible !important;
        }

        .messagePhoto {
            text-align: center;
            scale: 0.6;
        }

        .messageText{
            font-size: 0.6rem;
        }

        .messageData {
            padding: 0 !important;
            width: 100%;
        }
    
        .messageData th {
            font-size: 0.6rem;
            padding: 0.2rem !important;
            line-height: 1;
        }
    
        .messageData td {
            font-size: 0.5rem;
            padding: 0.2rem !important;
        }
    
        .messageSmall {
            font-size: 0.5rem;
            padding: 0 0.25rem !important;
        }
    
        .editQueryLink{
            font-size: 0.5rem;
        }
        .editQueryLink:hover{
            transform: scale(1.1);
        }
    
        .dataTableLink{
            font-weight: bold;
            color: #4285f4;
            cursor: pointer;
        }
    
        .messageEditButtonDiv{
            top: 4%;
            right: 2%;
    
            *{
                font-size: 0.5rem;
            }
        }

        .accessbilityBtns {
            top: 10%;
            left: 1%;
            transform: translateX(-100%);
            width: 4vw;
            gap: 0.4rem;
            flex-direction: column;
            align-items: center;

            svg{
                font-size: 0.7rem;
            }
        }

       

    }

    .MuiPaper-root {
        .statForm {
            width: 80vw;
            
            .MuiFormGroup-root{
                gap: 2rem;
                align-items: center;
    
                .formFields{
                    display: flex;
                    gap: 1rem;
                }
                .MuiButton-root{
                    width: 50%;
                    border-radius: 20px;
                    font-size: 1rem;
                }
            }
            
        }
    }
}


@media only screen and (max-width:600px) {
    .messageDiv {
        padding: 0.4rem 0 !important;

        >div{
            overflow: visible !important;
        }

        .messagePhoto {
            text-align: center;
            scale: 0.6;
        }

        .messageText{
            font-size: 0.6rem;
        }

        .messageData {
            padding: 0 !important;
            width: 100%;
        }
    
        .messageData th {
            font-size: 0.6rem;
            padding: 0.2rem !important;
            line-height: 1;
        }
    
        .messageData td {
            font-size: 0.5rem;
            padding: 0.2rem !important;
        }
    
        .messageSmall {
            font-size: 0.5rem;
            padding: 0 0.25rem !important;
        }
    
        .editQueryLink{
            font-size: 0.5rem;
        }
        .editQueryLink:hover{
            transform: scale(1.1);
        }
    
        .dataTableLink{
            font-weight: bold;
            color: #4285f4;
            cursor: pointer;
        }
    
        .messageEditButtonDiv{
            top: 4%;
            right: 2%;
    
            *{
                font-size: 0.5rem;
            }
        }

        .accessbilityBtns {
            top: 10%;
            left: 0%;
            transform: translateX(-100%);
            width: 4vw;
            gap: 0.4rem;
            flex-direction: column;
            align-items: center;

            svg{
                font-size: 0.7rem;
            }
        }

       

    }

    .MuiPaper-root {
        .statForm {
            width: 80vw;
            
            .MuiFormGroup-root{
                gap: 2rem;
                align-items: center;
    
                .formFields{
                    display: flex;
                    gap: 1rem;
                }
                .MuiButton-root{
                    width: 50%;
                    border-radius: 20px;
                    font-size: 1rem;
                }
            }
            
        }
    }
}
