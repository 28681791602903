.bouncing-loader {
    display: flex;
    justify-content: center;
    width: max-content;
    margin-bottom: 1rem;
    transform: translateY(0.5rem);
}

.bouncing-loader>div {
    width: 10px;
    height: 10px;
    margin: 2px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-14px);
    }
}

.bouncing-loader>div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
    animation-delay: 0.4s;
}