/**   This file for linking css for /home/github8_v360/diamond_analyst1/latestsharing/frontend/src/components/App/AppChildren/AppContainerChildren/Navigation.js
  *   We can add more properties or use this properties for other html components when required in next version.

*/

/**   This file for linking css for /latestsharing/frontend/src/components/App/AppChildren/AppContainerChildren/Navigation.js
  *   We can add more properties or use this properties for other html components when required in next version.
*/


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&display=swap');


#navigation .user-image {
  margin-right: 5px;
  width: 28px;
  height: 28px;
  border-radius: 50%;

}

html,
body {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Poppins', sans-serif;

}

#tutButton {
  font-size: 1.2rem;
  opacity: 0.7;
  margin: 0 2rem;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  font-weight: bold;
}

#tutButton:hover {
  opacity: 1;
}

#responsive-navbar-nav {
  .pathSymbol {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: grey;
  }
}

.signInButton {
  display: flex;
  flex-direction: row;
  width: 210px;
  background-color: white;
  box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 4px rgb(0 0 0 / 24%);
  border-radius: 17px;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  >img {
    height: 20px;
    width: 20px;
  }

  p {
    font-weight: bold;
  }
}

.signInButton:hover {
  transform: scale(1.1);
}

@media only screen and (max-width:1000px) {
  body {
    padding: 0 !important;
  }

  .signInButton {
    margin: 2rem auto;

  }

}