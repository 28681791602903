
.lbl-right{
    margin-right: 10px;
}

.lbl-size
{
    margin-right: 10px;
    margin-left: 0px;
}
.inp-center{
    width: 20%; 
    text-align: center;
}
.lbl-center
{
    font-size: 105%;
    align-items: center;
}
.easy-query b{
    font-weight: revert;
}
.modal-footer{
    justify-content: center;
}
.button-easy-query{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 5px 16px;
    border-radius: 20px;
}
.easyQueryModal{
    .modal-dialog{

        .modal-body{

            .easy-query{
                .row{
                    .col{
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;

                        >div{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            label{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 0.5rem;                                
                            }

                        }
                    }
                }
            }
        }
    }
}
.easyQueryModal{
    .modal-dialog{

        .modal-body{

            .easy-query{
                .row{
                    .col{
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;

                        >div{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            label{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 0.5rem;                                
                            }

                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 510px) {
    .button-easy-query {
     padding: 2px;
    }
    .easyQueryModal{
        .modal-dialog{
            width: 85%;
            margin: auto;

            .modal-body{
                padding: 0.5rem 1rem;

                .easy-query{
                    padding: 0;
                    .row{
                        .col{
                            gap: 0.5rem;
                            padding: 0.2rem 0 !important;

                            >div{
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                            }
                            .priceField{
                                input{
                                    width: 16vw;
                                }
                            }
                            label{
                                display: flex;
                                align-items: center;
                                font-size: 0.8rem !important;
                                justify-content: center;
                                input{
                                    transform: scale(0.8);
                                    padding: 0.3rem;
                                    border-radius: 15px;
                                    border: solid 1px grey;
                                }
                                
                            }
                            /* .lbl-size,.lbl-center,.lbl-right{
                               
                            } */
                        }
                    }
                }
            }

            .modal-footer{
                .btn{
                    padding: 0.5rem 1rem;
                }
            }

            .h3{
                font-size: 1rem;
                margin: auto;
            }
        }
    }
  }
.button-easy-query:nth-of-type(1){
    background-color: #4285F4;
}
.button-easy-query:nth-of-type(2){
    background-color: #0F9D58;
}
.button-easy-query:nth-of-type(3){
    background-color: #DB4437;
}