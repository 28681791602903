/* body {
	
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background: #fcf3ec;
	
} */

.button {
	
	--offset: 10px;
	--border-size: 2px;
	
	display: block;
	position: relative;
	padding: 1% 2%;
	appearance: none;
	border: 0;
	background: black;
	color: white;
	text-transform: uppercase;
	letter-spacing: .25em;
	outline: none;
    
	cursor: pointer;
	font-weight: bold;
	border-radius: 0;
	box-shadow: inset 0 0 0 var(--border-size) currentcolor;
	transition: background .8s ease;
	
	/* &:hover {
		background: rgba(100, 0, 0, .03);
	}
	
	&__horizontal,
	&__vertical {
		position: absolute;
		top: var(--horizontal-offset, 0);
		right: var(--vertical-offset, 0);
		bottom: var(--horizontal-offset, 0);
		left: var(--vertical-offset, 0);
		transition: transform .8s ease;
		will-change: transform;
		
		&::before {
			content: '';
			position: absolute;
			border: inherit;
		}
	}
	
	&__horizontal {
		--vertical-offset: calc(var(--offset) * -1);
		border-top: var(--border-size) solid currentcolor;
		border-bottom: var(--border-size) solid currentcolor;
		
		&::before {
			top: calc(var(--vertical-offset) - var(--border-size));
			bottom: calc(var(--vertical-offset) - var(--border-size));
			left: calc(var(--vertical-offset) * -1);
			right: calc(var(--vertical-offset) * -1);
		}
	}
	
	&:hover &__horizontal {
		transform: scaleX(0);
	}
	
	&__vertical {
		--horizontal-offset: calc(var(--offset) * -1);
		border-left: var(--border-size) solid currentcolor;
		border-right: var(--border-size) solid currentcolor;
		
		&::before {
			top: calc(var(--horizontal-offset) * -1);
			bottom: calc(var(--horizontal-offset) * -1);
			left: calc(var(--horizontal-offset) - var(--border-size));
			right: calc(var(--horizontal-offset) - var(--border-size));
		}
	}
	
	&:hover &__vertical {
		transform: scaleY(0);
	} */
	
}

.reset-btn{
	display: block;
	position: relative;
	appearance: none;
	border: 0;
	width: 120px;
	height: 30px;
}

.reset-txt, .reset-icon{
	color: rgb(117, 117, 117);
	font-size: 1rem;
}

.reset-txt{
	padding-left: 8px;
}

.reset-btn-container{
	margin-top: -10px;
	margin-right: -60px;
}

.remove-col-btn{
	padding: 1.5px 4px
}


@media screen and (max-width: 1000px) {
  .remove-col-btn {
	padding: 1px 2.5px;
  }
}

@media screen and (max-width: 700px) {
  .remove-col-btn {
	padding: 1.2px 2.5px;
  }
	
}