.mainContainer
{
    background-color: #F9F9F9;
    padding-bottom: 40px;
}
.cardContainer
{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: auto;
    margin-top: 0;
    width: 90%;
    justify-content: space-between;
    align-items: start;
    background-color: #F9F9F9;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.mainTitle{
    font-size: 2.4rem;
    font-weight: semibold;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 1.2px;
    line-height: 1.2;
    margin: 2rem 0;
    text-align: center;
    color: #333333;
    padding-top: 2rem;
}
.youtubeInfoCards{
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 3rem;
    padding: 2rem 4rem;
    border-radius: 20px;
    width: 100%;
    height: parent;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: space-evenly;
    align-items: flex-start;
}
.youtubeInfoCard{
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    gap: 2rem;
    padding: 2rem;
    border-radius: 20px;
    border: 1px solid #E5E5E5;
    width: fit-content;
    background-color: #EEEEEE;
    height: 200px;
}
.youtubeThumbnail{
    padding-top: 0.8rem;
    width: 90%;
    height: fit-content;
}

.youtubeThumbnail:hover{
    cursor: pointer;
    opacity: 0.8;
}

.youtubeInfoData{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: 'Numito', sans-serif;
    line-height: 1.2;
    gap: 1rem;
}

.youtubeTitle a{
    padding-top: 0.2rem;
    font-size: 1.3rem;
    font-weight: semibold;
    font-family: 'Nunito', sans-serif;
    line-height: 1.2;
    text-align: left;
    color: #333333;
    cursor: pointer;
}

.youtubeTitle a:hover::after{
    content: '▶️';
    font-size: 1rem;
    color: #000000;
    padding-left: 0.5rem;
}

.youtubeChannel a{
    font-size: 1rem;
    font-weight: 100;
    font-family: 'Nunito', sans-serif;
    line-height: 1.2;
    text-align: left;
    color: #007bff;
}

.youtubeChannel a:hover{
    color: #201D1D;
}

.appInfoCards{
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 3rem;
    padding: 2rem 4rem;
    border-radius: 20px;
    width: 100%;
    height: fit-content;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: space-evenly;
    align-items: flex-start;
}

.appInfoCard{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 20px;
    width: fit-content;
    width: fit-content;
    height: 200px;
}

.appInfoTitle{
    padding-top: 0.3rem;
    font-size: 1.6rem;
    font-weight: 900;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 1px;
    text-align: left;
    color: #151414;
}


.appInfoData{
    display: flex;
    flex-direction: column;
    font-family: 'Numito', sans-serif;
    line-height: 1.2;
    font-weight: 300;   
    width: 90%;
    gap: 1rem;
    color: #4a4a4a;
}
    

@media only screen and (max-width: 1000px) {
    .cardContainer{
        flex-direction: column;
        gap: 2rem;
        margin: auto;
        margin-top: 0;
        width: 90%;
        justify-content: space-between;
        align-items: start;
        background-color: #F9F9F9;
        height: 80vh;
        scrollbar-width: thin;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-color: #c5c5c5 #F9F9F9;
    }
    .youtubeInfoCards{
        display: flex;
        flex-direction: column;
        margin: auto;
        gap: 3rem;
        padding: 2rem 4rem;
        border-radius: 20px;
        width: 100%;
        height: parent;
        margin-top: 2rem;
        margin-bottom: 2rem;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .youtubeInfoCard{
        display: grid;
        grid-template-columns: 0.7fr 1fr;
        gap: 2rem;
        padding: 2rem;
        border-radius: 20px;
        border: 1px solid #E5E5E5;
        width: fit-content;
        background-color: #EEEEEE;
        height: fit-content;
    }
    .youtubeThumbnail{
        width: 70%;
        height: fit-content;
    }
    .youtubeInfoData{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Numito', sans-serif;
        line-height: 1.2;
        gap: 1rem;
    }

    .youtubeTitle{
        padding-top: 0.8rem;
        font-size: 1.2rem;
        font-weight: semibold;
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        text-align: left;
        color: #333333;
    }

    .youtubeChannel a{
        font-size: 1rem;
        font-weight: 100;
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        text-align: left;
        color: #007bff;
    }

    .youtubeChannel a:hover{
        color: #201D1D;
    }

    .appInfoCards{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        margin: auto;
        border-radius: 20px;
    }

    .appInfoCard{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        border-radius: 20px;
        width: fit-content;
        height: fit-content;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding: 3rem 2rem;
    }

    .appInfoCard:hover{
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        background-color: #F9F9F9;
    }

    .appInfoTitle{
        padding-top: 0.3rem;
        font-size: 1.7rem;
        font-weight: 900;
        font-family: 'Nunito', sans-serif;
        letter-spacing: 1px;
        text-align: left;
        color: #151414;
    }

    .appInfoData{
        display: flex;
        flex-direction: column;
        font-family: 'Numito', sans-serif;
        line-height: 1.2;
        font-weight: 300;   
        gap: 1rem;
        color: #4a4a4a;
        font-size: 1.2rem;
    }

    .youtubeThumbnail{
        width: 70%;
        height: fit-content;
    }

    .youtubeInfoData{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Numito', sans-serif;
        line-height: 1.2;
        gap: 1rem;
    }

    .youtubeTitle{
        padding-top: 0.8rem;
        font-size: 1.2rem;
        font-weight: semibold;
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        text-align: left;
        color: #333333;
    }

}

@media only screen and (max-width: 600px) {
    .cardContainer{
        flex-direction: column;
        gap: 2rem;
        margin: auto;
        margin-top: 0;
        width: 90%;
        justify-content: space-between;
        align-items: start;
        background-color: #F9F9F9;
        height: 80vh;
        scrollbar-width: thin;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-color: #c5c5c5 #F9F9F9;
    }
    .youtubeInfoCards{
        display: flex;
        flex-direction: column;
        margin: auto;
        gap: 3rem;
        padding: 2rem 4rem;
        border-radius: 20px;
        width: 100%;
        height: parent;
        margin-top: 2rem;
        margin-bottom: 2rem;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .youtubeInfoCard{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 2rem;
        border-radius: 20px;
        border: 1px solid #E5E5E5;
        width: fit-content;
        background-color: #EEEEEE;
        height: fit-content;
    }
    .youtubeThumbnail{
        width: fit-content;
        height: fit-content;
    }
    .youtubeInfoData{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Numito', sans-serif;
        line-height: 1.2;
        gap: 1rem;
    }

    .youtubeTitle{
        padding-top: 0.8rem;
        font-size: 1.2rem;
        font-weight: semibold;
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        text-align: left;
        color: #333333;
    }

    .youtubeChannel a{
        font-size: 1rem;
        font-weight: 100;
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        text-align: left;
        color: #007bff;
    }

    .youtubeChannel a:hover{
        color:#000000;
    }

}