.dateSelectContainer{
    display: flex;
    margin: 5rem auto;
    width: 100%;
    justify-content: center;
}
.dateSelectContainer > div{
    width: 30%;
}

.analysisBtn{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease;
}
.analysisLoading
{
    height: 1.5rem !important;
    width: 1.5rem !important;
}
.outputFiles .mdb-datatable>div:first-child {
    position:inherit
}
@media only screen and (max-width:1000px)
{
    .dateSelectContainer{
       flex-direction: column;
    }
    .dateSelectContainer > div{
        width: 100%;
    }
    .outputFiles .mdb-datatable>div:first-child {
        position:inherit
    }
    .analysisBtn{
        font-size: 0.5rem;
    padding: 0.5rem 0.5rem;
    }
}